<template>
  <div ref="zturn" class="zturn">
    <div
      v-for="(item, index) in zturnItems"
      :key="index"
      class="poster-item zturn-item"
      :data-n="index"
      @click="onItemClick(index)"
      @mouseover="stopAutoPlay"
      @mouseout="startAutoPlay"
      :style="item.style"
    >
      <img class="home_intrologo" :src="require(`@/static/home/${item.icon}`)" alt="极砚科技-创新科技,共研未来">
      <div class="home_intro_l">
        <p class="title">{{ item.name }}</p>
        <p class="con">{{ item.time }}</p>
      </div>
      <div class="home_intro_r">{{ item.con }}</div>
      <!-- 在这里放置轮播项的内容 -->
    </div>
  </div>
</template>

<script>
import { constants } from 'fs';
export default {
  name: "Zturn",
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    turning: {
      type: Number,
      default: 5000,
    },
    auto: {
      type: Boolean,
      default: true,
    },
    scale: {
      type: Number,
      default: 0.8,
    },
    opacity: {
      type: Number,
      default: 0.7,
    },
    Awidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      X: 0,
      timer: this.id,
      zturnItems: this.items.map((_) => ({ ..._, index: this.items.indexOf(_) })),
    };
  },
  beforeDestroy() {
    clearInterval(this.timer); // 添加清除定时器
  },
  computed: {
    num_li() {
      return this.items.length;
    },
    zturnPy() {
      return this.Awidth / (this.num_li - 1);
    },
  },
  mounted() {
    this.init();
    this.autoPlay();
  },
  methods: {
    init() {
      this.zturnItems.forEach((item, index) => {
        const rt = index - this.X > this.num_li / 2 || (index - this.X < 0 && index - this.X > -this.num_li / 2) ? -1 : 1;
        const offsetIndex = Math.abs(index - this.X);
        let adjustedIndex = offsetIndex;

        if (offsetIndex > this.num_li / 2) {
          adjustedIndex = this.X + this.num_li - index;
        } else if (index - this.X < -this.num_li / 2) {
          adjustedIndex = this.num_li + index - this.X;
        }

        const style  = {
          position: "absolute",
          left: "50%",
          marginLeft: -this.Awidth / 2 + this.zturnPy * rt * adjustedIndex + "px",
          zIndex: this.num_li - adjustedIndex,
          opacity: Math.pow(this.opacity, adjustedIndex),
          transform: `scale(${Math.pow(this.scale, adjustedIndex)}) rotate(-90deg) rotate(-90deg)`,
          WebkitTransform: `scale(${Math.pow(this.scale, adjustedIndex)}) rotate(-90deg)`,
        };

        // 使用Vue.set更新item.style
        this.$set(item, 'style', style);
      });
    },
    onItemClick(index) {
      this.X = index;
      this.init();
    },
    stopAutoPlay() {
      clearInterval(this.timer);
      this.timer = "";
    },
    startAutoPlay() {
      this.autoPlay();
    },
    autoPlay() {
      if (this.auto) {
        this.timer = setInterval(() => this.next_(), this.turning);
      }
    },
    next_() {
      this.X++;
      if (this.X >= this.num_li) {
        this.X = 0;
      }
      this.init();
    },
    prev_() {
      this.X--;
      if (this.X < 0) {
        this.X = this.num_li - 1;
      }
      this.init();
    },
  },
};
</script>

<style scoped lang='less'>
.zturn {
  /* 添加必要的CSS样式 */
  transform: rotate(90deg) translateY(25%) translateX(-25%);
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
}

.zturn-item {
  position: absolute;
  left: 50%;
  transition: all 0.5s ease;
}
.poster-item {
  width: 500px;
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px 0px rgba(153, 153, 153, 0.2);
  transition: all 0.5s;
  cursor: default;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transform: rotate(-90deg);
  overflow: hidden;
  display: flex;

  .home_intro_l {
    margin-left: 20px;
    width: 160px;

    .title {
      font-size: 18px;
      color: #222222;
      margin-top: 25px;
    }

    .con {
      font-size: 14px;
      color: #999999;
    }
  }

  .home_intro_r {
    font-size: 16px;
    color: #666666;
    margin-top: 30px;
  }
}
</style>