<template>
  <div>
    <Header navName="home"></Header>
    <!-- PC端 -->
    <div class="home_contentPC" v-if="!$isAndroid">
      <!-- banner -->
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <img class="img_sw" :src="require(`@/static/home/${slide.image}`)">
        </swiper-slide>
      </swiper>
      <!-- 服务内容 -->
      <div class="home_servicecon">
        <div class="home_title">
          <p class="title">服务内容</p>
          <p class="con">我们拥有经验丰富的专业团队，致力于提供最佳互联网解决方案</p>
        </div>
        <div class="home_servicecon_con">
          <div :class="['home_servicecon_con_region',item.path!=''?'hover':'']" v-for="(item, index) in anList" :key="index" @click="pathFun(item.path)">
            <img :src="require(`@/static/home/${item.icon}`)" width="90px" height="auto">
            <p class="wrap_title">{{ item.name }}<span v-if="item.names!=''">{{item.names}}</span></p>
            <div class="home_wrap_text">
              <div class="item" v-for="(itemk, indexk) in item.items" :key="indexk">{{ itemk }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 案例推荐 -->
      <div class="home_wrap">
        <div class="home_title">
          <p class="title">案例推荐</p>
          <p class="con">分析用户需求，打造优质互联网产品</p>
        </div>
        <div class="home_case">
          <div class="home_case_list" v-for="(item, index) in caseList" :key="index">
            <img class="home_casebg" :src="require(`@/static/home/${item.image}`)" alt="极砚OA系统">
          </div>
        </div>
      </div>
      <!-- 公司介绍 -->
      <div class="home_intro">
        <div class="home_intro_con">
          <div class="home_intro_con_l">
            <p>公司介绍</p>
            <div>
              极砚科技是一家专注于程序系统开发和网站小程序搭建的创新科技公司。我们致力于为客户提供高质量、定制化的技术解决方案。无论您需要开发一个创意独特的网站、搭建一个功能强大的小程序，还是希望进行系统优化和功能扩展，我们都能够提供完美的解决方案。通过创新和技术的融合，我们将为您开启无限的可能性。
            </div>
          </div>
          <div class="home_intro_con_r">
            <z-turn :id="uniqueId" :items="ztList" :Awidth="Awidth" :turning="turning" :auto="auto" :scale="scale"
              :opacity="opacity" />
          </div>
        </div>
      </div>
      <!-- 顾问式服务 -->
      <div class="home_service">
        <div class="home_title">
          <p class="title">顾问式服务</p>
          <p class="con">规范化开发流程，为用户提供更全、更优质的产品解决方案</p>
        </div>
        <div class="home_service_con">
          <img src="@/static/home/home_seyvice1.gif" alt="项目流程">
          <div class="home_service_text" :class="'service_text' + Number(index + 1)" v-for="(item, index) in yxList"
            :key="index">
            <p class="num">{{ item.num }}</p>
            <p class="title">{{ item.title }}</p>
          </div>
        </div>
      </div>


    </div>
    <div class="home_contentH5" v-else>
      <!-- banner -->
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <img class="img_sw" :src="require(`@/static/home/${slide.image}`)">
        </swiper-slide>
      </swiper>
      <!-- 定制开发服务 -->
      <div class="customized">
        <div class="title_h5">定制开发服务</div>
        <div class="heng"></div>

        <div class="customized_item">
          <img src="@/static/home/homea.png" class="homea_img" :style="{ marginLeft: homeaImgMargin }" alt="APP开发"/>
          <div class="customizedB_title">APP开发</div>
          <div class="applist">
            <div class="applist_item">社群电商APP</div>
            <div class="applist_item">直播培训APP</div>
            <div class="applist_item">直播电商APP</div>
            <div class="applist_item">在线教育APP</div>
            <div class="applist_item">旅游服务平台APP</div>
            <div class="applist_item">社区生活服务APP等</div>
          </div>
        </div>

        <div class="customized_item">
          <img src="@/static/home/homeb.png" class="homeb_img" :style="{ marginLeft: homebImgMargin }" alt="小程序开发"/>
          <div class="smallProgram">小程序开发</div>
          <div class="smallProgram_list">
            <div class="smallProgram_item">社群管理SCRM</div>
            <div class="smallProgram_item">知识付费</div>
            <div class="smallProgram_item">拼团小程序</div>
            <div class="smallProgram_item small_r1">接龙小程序</div>
            <div class="smallProgram_item">直播带货小程序</div>
            <div class="smallProgram_item">会议管理小程序等</div>
          </div>
        </div>

        <div class="customized_item" @click="pathFun('oa')">
          <img src="@/static/home/homec1.png" class="homec_img" :style="{ marginLeft: homecImgMargin }" alt="极砚OA办公系统"/>
          <div class="integrated">极砚OA办公系统</div>
          <div class="smallProgram_list">
            <div class="smallProgram_item">企业数字化</div>
            <div class="smallProgram_item">客户供应商管理</div>
            <div class="smallProgram_item">日常办公</div>
            <div class="smallProgram_item small_r2">企业信息化</div>
            <div class="smallProgram_item">办公审批</div>
            <div class="smallProgram_item">财务与合同管理</div>
          </div>
        </div>

      </div>
      <!-- 核心技术开发能力 -->
      <div class="core">
        <div class="title_h5">核心技术开发能力</div>
        <div class="heng"></div>
        <div class="core_context">
          核心开发团队来自腾讯，巨人，ATA等<br>技术联盟、Github技术共享
        </div>
        <div class="coreList">
          <div class="core_item">
            <img src="@/static/home/home_icona.png" class="core_item_imgA" alt="企业网站建设">
            <div class="core_item_title">高新资质</div>
            <div class="core_item_heng"></div>
            <div class="core_item_text">高新技术企业证书</div>
          </div>
          <div class="core_item">
            <img src="@/static/home/home_iconb.png" class="core_item_imgA" alt="微信/小程序开发">
            <div class="core_item_title">科技企业</div>
            <div class="core_item_heng"></div>
            <div class="core_item_text">民营科技企业证书</div>
          </div>
          <div class="core_item">
            <img src="@/static/home/home_iconc.png" class="core_item_imgA" alt="APP开发">
            <div class="core_item_title">双软认证</div>
            <div class="core_item_heng"></div>
            <div class="core_item_text">软件产品，软件企业</div>
          </div>
          <div class="core_item">
            <img src="@/static/home/home_icond.png" class="core_item_imgA" alt="极砚OA管理系统">
            <div class="core_item_title">软件著作权</div>
            <div class="core_item_heng"></div>
            <div class="core_item_text">诺干本著作权</div>
          </div>
        </div>
      </div>
      <!-- 案例展示 -->
      <div class="home_case">
        <div class="title_h5">案例展示</div>
        <div class="heng"></div>
        <div class="cases_context">分析用户需求，打造优质互联网产品</div>
        <div class="cases_list">
          <div class="cases_item">
            <img src="@/static/home/c1.jpg" alt="企业网站建设">
          </div>

          <div class="cases_item">
            <img src="@/static/home/c2.jpg" alt="微信/小程序开发">
          </div>

          <div class="cases_item">
            <img src="@/static/home/c3.jpg" alt="APP开发">
          </div>

          <div class="cases_item">
            <img src="@/static/home/c4.jpg" alt="极砚OA管理系统">
          </div>
        </div>

      </div>
      <!-- 客户品牌墙 -->
      <div class="home_customer">
        <div class="title_h5">客户品牌墙</div>
        <div class="heng"></div>
        <div class="home_customer_list">
          <img class="home_customer_item" alt="企业网站建设,微信/小程序开发,APP开发,极砚OA管理系统"
            :src="require(`@/static/home/${item.img}`)"
            v-for="(item,index) in pinList" :key="index">
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import ZTurn from '@/components/ZTurn'

export default {
  name: 'Home',
  components: {
    ZTurn,
  },
  data() {
    return {
      swiperOptions: {
        // 这里配置swiper的选项
        slidesPerView: 1,
        spaceBetween: 0,
        // ... 其他配置
      },
      slides: [
        { image: 'banner1.jpg' },
        { image: 'banner2.jpg' },
        { image: 'banner3.jpg' },
        // ... 其他轮播项
      ],
      anList: [
        {
          name: "企业网站建设",
          names: "",
          icon: "wzjs.png",
          items: ['生活服务', '电商/零售', '企业展示', '餐饮/外卖'],
          path: ""
        },
        {
          name: "微信/小程序开发",
          names: "",
          icon: "wxxcx.png",
          items: ['微官网', 'H5活动', '微商城', '微分销'],
          path: ""
        },
        {
          name: "APP开发（苹果+安卓）",
          names: "",
          icon: "appkf.png",
          items: ['电商类', '直播类', '知识付费类', '旅游平台类'],
          path: ""
        },
        {
          name: "高效办公",
          names: "极砚OA",
          icon: "cylogo.jpg",
          items: ['企业数字化', '客户管理', '企业信息化', '财务管理'],
          path: "oa"
        }
      ],
      caseList: [
        { image: "c1.jpg" },
        { image: "c2.jpg" },
        { image: "c3.jpg" },
        { image: "c4.jpg" },
        { image: "c5.jpg" },
        { image: "c6.jpg" },
        { image: "c7.jpg" },
        { image: "c8.jpg" },
      ],

      uniqueId: "zturnComponent", // 为组件提供一个唯一的ID
      Awidth: 400, // 轮播项的宽度
      turning: 5000, // 自动切换间隔时间
      auto: true, // 是否自动切换
      scale: 0.8, // 缩放比例
      opacity: 0.7, // 透明度
      ztList: [
        {
          icon: 'z1.png',
          name: '东风裕隆',
          time: '2024-04-23',
          con: '文物鉴定小程序',
        },
        {
          icon: 'z2.png',
          name: '中锐教育',
          time: '2024-04-23',
          con: '电子会员卡系统',
        },
        {
          icon: 'z3.png',
          name: '锦江之星',
          time: '2024-04-23',
          con: '内部工单派发监控系统',
        },
        {
          icon: 'z4.png',
          name: '大王',
          time: '2024-04-23',
          con: '官方网站',
        },
        {
          icon: 'z5.png',
          name: '国家电网公司',
          time: '2024-03-27',
          con: '微信官方网站',
        },
      ],
      yxList: [
        {
          num: '01',
          title: '意向洽谈',
        },
        {
          num: '02',
          title: '需求梳理',
        },
        {
          num: '03',
          title: '签约合作',
        },
        {
          num: '04',
          title: '原型设计',
        },
        {
          num: '05',
          title: 'UI设计',
        },
        {
          num: '06',
          title: '系统开发',
        },
        {
          num: '07',
          title: '测试交付',
        },
        {
          num: '08',
          title: '上线运营',
        },
      ],

      homeaImgMargin: '',
      homebImgMargin: '',
      homecImgMargin: '',

      pinList: [
        {img: 'h1.png'},
        {img: 'h2.png'},
        {img: 'h3.png'},
        {img: 'h4.png'},
        {img: 'h5.png'},
        {img: 'h6.png'},
        {img: 'h7.png'},
        {img: 'h8.png'},
        {img: 'h9.png'},
        {img: 'h11.png'},
        {img: 'h11.png'},
        {img: 'h12.png'}
      ],
    }
  },
  mounted() {
    if(this.$isAndroid) {
      this.checkImageMargins();
      window.addEventListener('scroll', this.checkImageMargins);
    }
  },
  beforeDestroy() {
    if(this.$isAndroid) {
      window.removeEventListener('scroll', this.checkImageMargins);
    }
  },
  methods: {
    pathFun(url) {
      if(url != '') {
        this.$router.push({ path: url });
      }
    },
    checkImageMargins() {
      const scroH = window.pageYOffset || document.documentElement.scrollTop;
      const viewHa = this.$el.querySelector('.homea_img').offsetTop - window.innerHeight;
      const viewHb = this.$el.querySelector('.homeb_img').offsetTop - window.innerHeight;
      const viewHc = this.$el.querySelector('.homec_img').offsetTop - window.innerHeight;
      if (scroH > viewHa) {
        this.homeaImgMargin = '0.33rem';
      }
      if (scroH > Number(viewHb + 100)) {
        this.homebImgMargin = '0.45rem';
      }
      if (scroH > viewHc) {
        this.homecImgMargin = '0.65rem';
      }
    },
  },
}
</script>

<style scoped lang='less'>
.home_contentPC {
  .img_sw {
    display: block;
    /* 使图片作为块级元素显示 */
    width: 100%;
    /* 宽度设置为100% */
    margin-left: auto;
    /* 左边距设置为自动 */
    margin-right: auto;
    /* 右边距设置为自动 */
  }

  .home_title {
    text-align: center;
    padding-top: 40px;

    .title {
      font-size: 24px;
      color: #222222;
      text-align: center;
    }

    .con {
      font-size: 12px;
      color: #999999;
      font-weight: 300;
      margin-top: 6px;
    }
  }

  .home_servicecon {
    width: 1200px;
    background: url(~@/static/home/home_toolbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    padding-bottom: 40px;
    margin: 0 auto;

    .home_servicecon_con {
      display: flex;
      justify-content: space-between;
      margin-top: 36px;
      padding: 40px 0;
      background: #fff;

      .home_servicecon_con_region {
        border-right: 1px solid #f0f0f0;
        background-color: #ffffff;
        text-align: center;
        &.hover{
          cursor: pointer;
        }

        .wrap_title {
          font-size: 20px;
          color: #444;
          span{
            color: #ff641f;
          }
        }

        .home_wrap_text {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          /* 如果需要垂直居中，可以使用align-items: center; */
          font-size: 16px;
          line-height: 30px;
          color: #999999;
          // margin: 20px auto;
          padding: 20px;

          /* 可选的内边距 */
          .item {
            flex: 0 0 50%;
            /* 每个项目占据50%宽度 */
            text-align: center;
            /* 文字水平居中 */
            line-height: 30px;
            /* 行高与容器高度相同可垂直居中文本 */
            box-sizing: border-box;
            /* 防止padding影响宽度 */
          }
        }
      }
    }
  }

  .home_wrap {
    width: 100%;
    background: #f8f8f8;

    .home_case {
      width: 1200px;
      margin: 0 auto;
      padding: 35px 0 80px;
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;

      .home_case_list {
        width: 290px;
        height: 230px;
        position: relative;

        .home_casebg {
          width: 290px;
          height: 230px;
        }
      }
    }
  }

  .home_intro {
    width: 100%;
    height: 566px;
    background: url(~@/static/home/home_introbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    padding: 50px 0;

    .home_intro_con {
      display: flex;
      width: 1200px;
      margin: 0 auto;

      .home_intro_con_l {
        p {
          font-size: 24px;
          color: #222222;
          margin-top: 170px;
        }

        div {
          width: 499px;
          font-size: 16px;
          text-align: justify;
          line-height: 27px;
          color: #666666;
          margin-top: 20px;
        }
      }

      .home_intro_con_r {
        width: 500px;
        height: 500px;
        margin-left: 120px;
        margin-top: 56px;
        position: relative;
      }
    }
  }

  .home_service {
    width: 100%;
    height: 642px;
    background-color: #ffffff;

    .home_service_con {
      width: 1200px;
      height: 400px;
      margin: 33px auto 0;
      position: relative;
      padding-left: 3px;

      .home_service_text {
        position: absolute;
        text-align: center;

        .num {
          font-family: Arial-BoldMT;
          font-size: 20px;
          color: #222222;
        }

        .title {
          font-size: 18px;
          color: #222222;
        }
      }

      .service_text1 {
        left: 0;
        top: 119px;
      }

      .service_text2 {
        left: 176px;
        top: 333px;
      }

      .service_text3 {
        left: 328px;
        top: 118px;
      }

      .service_text4 {
        left: 493px;
        top: 333px;
      }

      .service_text5 {
        left: 664px;
        top: 118px;
      }

      .service_text6 {
        left: 811px;
        top: 333px;
      }

      .service_text7 {
        left: 977px;
        top: 116px;
      }

      .service_text8 {
        left: 1130px;
        top: 333px;
        white-space: nowrap;
      }
    }
  }
}

.home_contentH5 {
  .img_sw {
    display: block;
    /* 使图片作为块级元素显示 */
    width: 100%;
    /* 宽度设置为100% */
    margin-left: auto;
    /* 左边距设置为自动 */
    margin-right: auto;
    /* 右边距设置为自动 */
  }

  .title_h5 {
    font-family: PingFangSC-Medium;
    font-size: 0.54rem;
    line-height: 0.54rem;
    font-weight: 700;
    color: #1d1d1d;
    text-align: center;
  }

  .heng {
    width: 0.667rem;
    height: 0.046rem;
    background-color: #0bd0c0;
    border-radius: 0.02rem;
    margin: 0.32rem auto;
  }

  .customized {
    background-color: #f8f8f8;

    .title_h5 {
      margin-top: 1.07rem;
    }

    .customized_item {
      .homea_img {
        width: 9.67rem;
        height: 6.64rem;
        display: block;
        margin: 0.45rem 0 0 -100vw;
        -moz-transition: margin-left 2s;
        -webkit-transition: margin-left 2s;
        -o-transition: margin-left 2s;
      }

      .homeb_img {
        width: 9.26rem;
        height: 9.27rem;
        margin-left: -100vw;
        -moz-transition: margin-left 2s;
        -webkit-transition: margin-left 2s;
        -o-transition: margin-left 2s;
      }

      .homec_img {
        width: 9.35rem;
        height: 6.39rem;
        display: block;
        margin: 0 0 0 -100vw;
        transition: margin-left 2s;
        -moz-transition: margin-left 2s;
        -webkit-transition: margin-left 2s;
        -o-transition: margin-left 2s;
      }

      .customizedB_title,
      .smallProgram,
      .integrated {
        font-size: 0.48rem;
        font-family: PingFang SC Medium, PingFang SC Medium-Medium;
        font-weight: 500;
        color: #111;
        line-height: 0.48rem;
        text-align: center;
      }

      .smallProgram {
        margin: -0.58rem auto 0;
      }

      .integrated {
        margin: 0.51rem auto 0.58rem;
      }

      .applist {
        width: 100%;
        height: 1.8rem;
        padding: 0 1.062rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0.59rem auto 0.57rem;

        .applist_item:nth-child(3) {
          margin-right: 0;
        }

        .applist_item {
          font-size: 0.34rem;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #666;
          line-height: 0.34rem;
          display: flex;
          align-items: center;
          margin-right: 0.4rem;

          &::before {
            content: "";
            border-radius: 50%;
            background-color: #0bd0c0;
            display: block;
            font-size: 0.13rem;
            width: 0.13rem;
            height: 0.13rem;
            margin-right: 0.204rem;
          }
        }
      }

      .smallProgram_list {
        width: 100%;
        height: 1.546rem;
        padding: 0 1.722rem 0 1.102rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0.58rem auto 1.06rem;

        .smallProgram_item:nth-child(3) {
          margin-right: 0;
        }

        .small_r1 {
          margin-right: 1rem !important;
        }

        .small_r2 {
          margin-right: 0.36rem !important;
        }

        .smallProgram_item {
          font-size: 0.34rem;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #666;
          line-height: 0.34rem;
          display: flex;
          align-items: center;
          margin-right: 0.35rem;

          &::before {
            content: "";
            border-radius: 50%;
            background-color: #0bd0c0;
            display: block;
            font-size: 0.13rem;
            width: 0.13rem;
            height: 0.13rem;
            margin-right: 0.204rem;
          }
        }
      }



    }
  }

  .core {
    height: 12.5rem;
    background: #fff;
    padding: 1px 0;
    .title_h5 {
      margin: 0.86rem auto 0;
    }

    .core_context {
      width: 8.57rem;
      font-size: 0.34rem;
      color: #888;
      line-height: 0.54rem;
      text-align: center;
      margin: 0 auto;
    }

    .core_item_text,
    .core_item_title {
      font-family: PingFang SC Regular, PingFang SC Regular-Regular;
      font-weight: 400;
      color: #fff;
      text-align: center;
    }

    .coreList {
      width: 9.2rem;
      height: 8.54rem;
      margin: 0.74rem auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .core_item {
        width: 4.59rem;
        height: 4.27rem;
        text-align: center;

        &:nth-child(1) {
          background: #505874;
        }

        &:nth-child(2) {
          background: #556e9c;
        }

        &:nth-child(3) {
          background: #6396b7;
        }

        &:nth-child(4) {
          background: #82c3d7;
        }

        img {
          max-width: 0.8rem;
          max-height: 0.78rem;
          display: block;
          margin: 0.96rem auto 0.72rem;
        }

        .core_item_title {
          font-size: 0.43rem;
          line-height: 0.43rem;
        }

        .core_item_heng {
          width: 0.64rem;
          height: 1px;
          background: #fff;
          margin: 0.26rem auto 0.38rem;
        }

        .core_item_text {
          font-size: 0.3rem;
          line-height: 0.3rem;
        }
      }
    }
  }

  .home_case {
    .title_h5 {
      margin: 1.25rem auto 0;
    }

    .cases_context {
      font-size: 0.34rem;
      font-family: PingFang SC Regular, PingFang SC Regular-Regular;
      font-weight: 400;
      text-align: center;
      color: #888;
      line-height: 0.54rem;
      width: 8.48rem;
      margin: 0 auto;
    }

    .cases_list {
      width: 10rem;
      height: 7.41rem;
      padding: 0 0.38rem;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0.68rem auto;

      .cases_item {
        width: 4.52rem;
        height: 3.59rem;
        border-radius: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;

        img {
          width: 4.352rem;
          height: 3.426;
        }
      }
    }
  }

  .home_customer{
    width: 10rem;
    height: 7.12rem;
    background: #fff;
    padding: 1px 0;
    box-sizing: border-box;
    .title_h5 {
      margin: 0.83rem auto 0;
    }
    .home_customer_list {
      width: 100%;
      height: 3.69rem;
      padding: 0 0.4rem;
      box-sizing: border-box;
      margin: 0.53rem auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .home_customer_item {
        width: 2.1rem;
        height: 1.05rem;
        border: 1px solid #e1e1e1;
      }
    }

  }
}
</style>